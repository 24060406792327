<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Compras</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Recibo Orden de Compra</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row mb-4"
    >
      <h1>RECIBO / ORDEN DE COMPRA # {{ this.$route.params.id }}</h1>
    </div>
    <div class="grid border-bottom-3" style="background-color: #f8f9fa">
      <div class="col-6">
        <p class="mb-0">
          <strong>Fecha Recepci&oacute;n: </strong> <span>{{ recibo.fecha_recepcion ?? 'No definido' }}</span>
        </p>
        <p class="mb-0">
          <strong>Recibo #: </strong> <span>{{ recibo.id ?? 'No definido'}}</span>
        </p>
      </div>
      <div class="col-6">
        <p class="mb-0">
          <strong>Almacenado en: </strong>
          <span>{{ recibo.nombre_sucursal ?? 'No definido' }}</span>
        </p>
      </div>
    </div>
    <div class="grid mt-4">
      <DataTable ref="dt_oc" :value="detalle_recibo" class="col-12"
        responsiveLayout="scroll"
      >
        <Column field="fabrica_nombre" header="LABORATORIO">
          {{ producto.fabrica_nombre }}
        </Column>
        <Column field="descripcion" header="DESCRIPCIÓN/PRODUCTO">
          {{ producto.descripcion }}
        </Column>
        <Column field="unidad_medida_nombre" header="UNIDAD MEDIDA">
          {{ producto.unidad_medida_nombre }}
        </Column>
        <Column
          class="text-right"
          field="pivot.cantidad"
          header="CANTIDAD SOLICITADA"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad) }}
          </template>
        </Column>
        <Column class="text-right" field="pivot.precio" header="PRECIO COMPRA">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
          </template>
        </Column>
        <Column header="TOTAL" class="text-right">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.pivot.cantidad * slotProps.data.pivot.precio
              )
            }}
          </template>
        </Column>

        <template #footer>
          <div class="text-lg flex align-items-end justify-content-end">
            <h5><strong>Total Pagado :</strong> {{ convertirNumeroGermanicFormat(total_venta) }} Bs</h5>
          </div>
        </template>
      </DataTable>
    </div>
    <div class="flex col-12 justify-content-end">
      <Button @click="goBack" class="mr-2">
        <i class="pi pi-arrow-circle-left"></i>
        &nbsp;ATR&Aacute;S
      </Button>
    </div>
  </div>
</template>

<script>
import OCRecepcionService from "@/service/OCRecepcionService.js";

export default {
  data() {
    return {
      recibo: {},
      detalle_recibo: [],
    };
  },
  ocrecepcionService: null,
  computed: {
    total_venta() {
      let total = 0;
      this.detalle_recibo.forEach((item) => {
        total += item.pivot.cantidad * item.pivot.precio;
      });
      return total;
    },
  },
  created() {
    this.ocrecepcionService = new OCRecepcionService();
  },
  mounted() {
    this.cargarDetalle();
  },
  methods: {
    cargarDetalle() {
      this.ocrecepcionService
        .obtenerRecibo(this.$route.params.id)
        .then((data) => {
          this.recibo = data.datos_recepcion;
          this.detalle_recibo = data.detalle;
        })
        .catch((error) => console.log(error));
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
